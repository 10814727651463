.mainWrap {
    min-width: 600px;
    max-width: 100%;
    margin: 0 auto;
}

.banner_content {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    margin-top: 90px;
    background-color: #34495e;
    /* border-bottom: 1px solid #1abc9c; */
    box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.3);
}

.form_section {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: 40px 0;
    background-color: #2c3e50;
    border-radius: 8px;
    padding: 30px;
    box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.3);
    border: 1px solid #1abc9c;
    width: 90%;
    max-width: 1000px;
}

.form_title {
    text-align: center;
    color: #1abc9c;
    font-size: 28px;
    margin-bottom: 30px;
}

.form {
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.input_group {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.input_group label {
    color: #ecf0f1;
    font-size: 18px;
    font-weight: bold;
}

.input_field,
.textarea_field {
    padding: 12px;
    background-color: #34495e;
    border: 1px solid #1abc9c;
    border-radius: 4px;
    color: #ecf0f1;
    font-size: 16px;
    transition: all 0.3s ease;
}

.input_field:focus,
.textarea_field:focus {
    outline: none;
    border-color: #e74c3c;
    box-shadow: 0 0 5px rgba(26, 188, 156, 0.5);
}

.textarea_field {
    resize: vertical;
    min-height: 200px;
}

.button_group {
    display: flex;
    gap: 15px;
    justify-content: center;
    margin-top: 20px;
    padding-top: 20px;
}

.submit_button,
.cancel_button {
    padding: 12px 30px;
    border: none;
    border-radius: 4px;
    font-size: 16px;
    font-weight: bold;
    cursor: pointer;
    transition: background-color 0.3s;
}

.submit_button {
    background-color: #1abc9c;
    color: #ecf0f1;
}

.submit_button:hover {
    background-color: #e74c3c;
}

.cancel_button {
    background-color: #7f8c8d;
    color: #ecf0f1;
}

.cancel_button:hover {
    background-color: #95a5a6;
}

/* 반응형 디자인 */
@media (max-width: 768px) {
    .form_section {
        width: 95%;
        padding: 20px;
    }

    .button_group {
        flex-direction: column;
    }

    .submit_button,
    .cancel_button {
        width: 100%;
    }
}

.info_group {
    display: flex;
    gap: 2rem;
    font-size: 16px;
    align-items: right;
}

.info_item {
    display: flex;
    align-items: center;
    gap: 0.5rem;
}

.info_item label {
    font-weight: 600;
    color: #ecf0f1;
}

.info_item span {
    color: #ecf0f1;
}

.file_upload_area {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;

}

.file_input {

    padding: 12px;
    background-color: #34495e;
    border: 1px solid #1abc9c;
    border-radius: 4px;
    color: #ecf0f1;
    font-size: 16px;
}

.file_name {
    font-size: 0.9rem;
    color: #ecf0f1;
    padding: 0.5rem;
    background-color: #34495e;
    word-break: break-all;
}

.buttons_container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 20px;
}

.right_buttons {
    display: flex;
    gap: 10px;
}

.list_button {
    /* submit_button과 동일한 스타일 적용 */
    color: #ecf0f1;
    background-color: #7f8c8d;
    padding: 12px 30px;
    border: none;
    border-radius: 4px;
    font-size: 16px;
    font-weight: bold;
    cursor: pointer;
    transition: background-color 0.3s;
}

.list_button:hover {
    background-color: #95a5a6;
}