/* 기본 폰트 설정 */
body {
    font-family: sans-serif;
    background-color: #2c3e50;
    /* 어두운 배경색 */
    color: #ecf0f1;
    /* 밝은 텍스트 색상 */
    margin: 0;
    padding: 0;
}

/* 컨테이너 기본 설정 */
.mainWrap {
    min-width: 600px;
    max-width: 100%;
    margin: 0 auto;
}

/* 섹션 구분을 위한 스타일 */
.section {
    margin-bottom: 40px;
}

/* 배너 콘텐츠 영역 */
.banner_content {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    margin-top: 90px;
    background-color: #34495e;
    /* 어두운 청록색 배경 */
    /* border-bottom: 1px solid #1abc9c; */
    /* 섹션 구분선 */
    box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.3);
    /* 부드러운 그림자 효과 */
}

/* 프로필 사진 */
.profile_photo img {
    width: 100%;
    max-width: 200px;
    height: auto;
    border-radius: 50%;
    border: 5px solid #2c3e50;
    object-fit: cover;
    box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.2);
    /* 사진 주변 그림자 */
}

/* 프로필 설명 */
.profile_description {
    text-align: center;
    margin-top: 20px;
    letter-spacing: 0.5px;
}

.profile_description .name {
    font-size: 50px;
    font-weight: 900;
    margin-bottom: 15px;
    letter-spacing: 1px;
    color: #ecf0f1;
    /* 밝은 텍스트 */
}

.profile_description h2 {
    font-size: 28px;
    margin-bottom: 15px;
}

.profile_description p {
    font-size: 18px;
    line-height: 1.8;
    letter-spacing: 0.5px;
    color: #bdc3c7;
    /* 회색 톤 */
}

/* 내용 래퍼 */
.content_wrapper {
    display: flex;
    margin-left: 20px;
    margin-right: 20px;
    margin-top: 40px;
    margin-bottom: 40px;
    width: 70%;
    box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.3);
}

/* 텍스트 섹션 */
.text_section {
    flex: 1;
    padding: 20px;
    background-color: #2c3e50;
    /* 다크 그레이 */
    min-width: 300px;
    border-radius: 8px;
    border: 1px solid #1abc9c;
    /* 구분선 */
}

.text_section h2 {
    font-size: 28px;
    margin-bottom: 20px;
    color: #ecf0f1;
    /* 밝은 텍스트 */
}

.text_section p {
    font-size: 18px;
    line-height: 1.8;
    color: #bdc3c7;
    /* 밝은 회색 톤 */
}

.text_section ul {
    list-style-type: none;
    /* 기본 리스트 스타일 제거 */
    padding: 0;
    margin: 0;
}

.text_section ul li {
    font-size: 18px;
    margin: 8px 0;
    /* 항목 간 간격 */
    padding-left: 20px;
    position: relative;
}

.text_section ul li:before {
    content: '•';
    /* 원형 기호로 각 항목 앞에 표시 */
    position: absolute;
    left: 0;
    color: #1abc9c;
    /* 원형 기호 색상 */
    font-size: 22px;
}

.text_section ul li:hover {
    color: #e74c3c;
    /* 항목 hover 시 색상 변화 */
}

.text_section Button {
    margin-top: 20px;
    padding: 10px 20px;
    background-color: #e74c3c;
    /* 버튼 색상 */
    color: white;
    border-radius: 5px;
    font-size: 16px;
    text-transform: uppercase;
}

.text_section Button:hover {
    background-color: #c0392b;
    /* 버튼 hover 시 어두운 레드 */
    transform: scale(1.05);
    /* 버튼 크기 확장 */
}

/* 이미지 갤러리 */
.image_gallery {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 20px;
    /* 모든 방향 간격 균일하게 설정 */
    background-color: #2c3e50;
    padding: 20px;
    border-radius: 8px;
    border: 1px solid #1abc9c;
    align-content: center;
    /* 수직 방향에서도 정렬 */
}

/* 이미지 갤러리 */
.image_gallery {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 0 20px;
    /* 수평 간격만 설정 */
    row-gap: 30px;
    /* 수직 간격 별도 설정 */
    background-color: #2c3e50;
    padding: 20px;
    border-radius: 8px;
    border: 1px solid #1abc9c;
    align-content: center;
}

/* 개별 이미지 아이템 */
.image_item {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    /* 상하 균일 간격 유지 */
    text-align: center;
    width: 150px;
    height: auto;
    /* 자동 높이 */
    margin-bottom: 0;
    box-sizing: border-box;
}

.image_item img {
    width: 100%;
    height: 150px;
    /* 이미지 높이 고정 */
    border-radius: 50%;
    object-fit: cover;
    box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.2);
    margin-bottom: 10px;
    /* 이미지와 텍스트 사이 일정 간격 */
}


/* 게시판 섹션 스타일 */
.table_section {
    display: flex;
    flex-direction: column;
    justify-content: center;
    /* margin-left: 20px;
    margin-right: 20px; */
    margin-top: 40px;
    margin-bottom: 40px;
    background-color: #2c3e50;
    border-radius: 8px;
    padding: 20px;
    box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.3);
    border: 1px solid #1abc9c;
    width: 90%;
}

.brdTable_title {
    text-align: center;
    color: #1abc9c;
}

.table {
    border: #e74c3c;
    /* border-collapse: collapse; */
    width: 100%;
    table-layout: fixed;
}



.table th,
.table td {
    padding: 12px;
    text-align: left;
    word-wrap: break-word;
}

.table tr:hover {
    background-color: #1abc9c;
    transition: 0.3s;
}

.text_section ul li:hover {
    color: #e74c3c;
    transition: 0.3s;
    /* 항목 hover 시 색상 변화 */
}


/* TableContainer 스타일 */
.tableContainer {
    background-color: #34495e;
    width: 100%;
    margin-top: 20px;
    border: 1px solid #1abc9c;
    box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.3);
}

.brdTable_head tr th {
    background-color: #34495e;
    color: #ecf0f1;
    font-size: 25px;
    font-weight: bold;
    text-align: center;
}

.brdTable_body {
    background-color: #34495e;
}


.brdTable_body tr:hover {
    background-color: #4a6a8b;
    transition: 0.3s;
}


.brdTable_body tr:hover td {

    color: #e74c3c;
    transition: 0.3s;
}

.brdTable_body td {
    color: #ecf0f1;
    font-size: 20px;
    text-align: center;
}

/* 열별 고정 너비 설정 */
.brdTable_cell_idx {
    width: 20%;
}

.brdTable_cell_title {
    width: 30%;
    /* 제목은 넓게 */
    cursor: pointer;
    text-align: left !important;
}

.brdTable_cell_regdt {
    width: 20%;
}

.brdTable_cell_regid {
    width: 20%;
}

.comment_button {

    margin-top: 20px !important;
    background-color: #1abc9c !important;
    color: #ecf0f1;
    width: 50%;
    align-self: center;

    &:hover {
        background-color: #e74c3c !important;
    }

}