:root {
  --primary: #1abc9c;
  --secondary: #2c3e50;
  --white: #ffffff;
  --gray: #95a5a6;
  --header-height: 60px;
  --max-width: 1200px;
  --padding: 20px;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  font-family: 'Arial', sans-serif;
  line-height: 1.6;
  background: var(--secondary);
  color: var(--white);
}

.container {
  width: 100%;
  max-width: var(--max-width);
  margin: 0 auto;
  padding: var(--padding);
}

button {
  cursor: pointer;
  border: none;
  padding: 8px 16px;
  border-radius: 4px;
  background: var(--primary);
  color: var(--white);
}

input, textarea {
  width: 100%;
  padding: 8px;
  border: 1px solid var(--gray);
  border-radius: 4px;
}