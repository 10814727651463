.mainWrap {
    min-width: 600px;
    max-width: 100%;
    margin: 0 auto;
}

.banner_content {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    margin-top: 90px;
    background-color: #34495e;
    /* border-bottom: 1px solid #1abc9c; */
    box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.3);
    min-height: calc(100vh - 200px);
}

.signup_container {
    width: 100%;
    max-width: 600px;
    margin: 40px auto;
}

.signup_paper {
    padding: 30px;
    background-color: #2c3e50 !important;
    border: 1px solid #1abc9c;
    border-radius: 8px;
    box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.3);
    margin-bottom: 40px;
}

.signup_paper h2 {
    color: #ecf0f1;
    text-align: center;
    margin-bottom: 20px;
    font-size: 28px;
}

/* 에러 메시지 스타일 */
.MuiFormHelperText-root {
    color: #e74c3c !important;
}

.file_input {
    margin-top: 20px;
    margin-bottom: 20px;
    color: #ecf0f1;
}

.button_group {
    display: flex;
    justify-content: flex-end;
}

.submit_button {
    background-color: #1abc9c;
    color: #fff;
    border: none;
    padding: 10px 20px;
    margin-left: 20px;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s;
}

.cancel_button {
    background-color: #7f8c8d;
    color: #fff;
    border: none;
    padding: 10px 20px;
    margin-left: 20px;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s;
}

.submit_button:hover {
    background-color: #e74c3c;
}
.cancel_button:hover {
    background-color: #95a5a6;
}

/* 반응형 디자인 */
@media (max-width: 768px) {
    .form_section {
        width: 95%;
        padding: 20px;
    }

    .button_group {
        flex-direction: column;
    }

    .submit_button, .cancel_button {
        width: 100%;
    }
}