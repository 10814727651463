.header {
  width: 100%;
  height: 60px;
  margin: 0;
  padding: 0 20px;
  box-sizing: border-box;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 100;
  background-color: #fff;
}

.header_content {
  max-width: 1200px;
  width: 100%;
  height: 100%;
  margin: 0 auto;
  display: flex;
  align-items: center;
}

.wrap {
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px 20px;
  /* background: linear-gradient(#122579, hsl(182, 67%, 26%)); */
  background-color: #1abc9c;
  /* 깔끔한 배경 */
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  /* 헤더의 너비를 화면 전체로 설정 */
  z-index: 999;
}

/* 좌측 로고 스타일 */
.logo h1 {
  margin: 0;
  padding-left: 30px;
  font-size: 35px;
  font-family: 'Roboto', sans-serif;
  font-weight: bold;
}

.logo h1 a {
  text-decoration: none;
  color: #ecf0f1;
  /* 다크 블루 계열 */
  transition: color 0.3s ease;
}

.logo h1 a:hover {
  color: #e74c3c;
  /* 마우스오버 시 밝은 블루 */
}


/* 우측 메뉴 스타일 */
.menu {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 20px;
  padding-right: 50px;
  /* 메뉴와 화면 오른쪽 여백 설정 */
}

.menu a {
  text-decoration: none;
  color: #ecf0f1;
  /* 블루-그레이 계열 */
  font-size: 25px;
  font-family: 'Roboto', sans-serif;
  transition: color 0.3s ease, transform 0.2s ease;
}

.menu a:hover {
  color: #e74c3c;
  /* 마우스오버 시 밝은 민트 계열 */
  transform: translateY(-2px);
  /* 살짝 위로 이동 */
}

/* 버튼 스타일 */
.menu a.button {
  background-color: #e74c3c;
  /* 따뜻한 레드 */
  color: white;
  padding: 10px 15px;
  border-radius: 5px;
  font-size: 16px;
  font-family: 'Roboto', sans-serif;
  font-weight: bold;
  transition: background-color 0.3s ease, transform 0.2s ease;
}

.menu a.button:hover {
  background-color: #c0392b;
  /* 마우스오버 시 더 어두운 레드 */
  transform: scale(1.05);
  /* 살짝 확대 */
}