/* 기본 레이아웃 */
.mainWrap {
  flex: 1;
  width: 100%;
  padding: 0;
  margin: 0;
  display: flex;
  flex-direction: column;
}

.banner_content {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  margin-top: 90px;
  background-color: #34495e;
  /* border-bottom: 1px solid #1abc9c; */
  /* box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.3); */
}

.table_section {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-top: 40px;
  margin-bottom: 40px;
  background-color: #2c3e50;
  border-radius: 8px;
  padding: 20px;
  box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.3);
  border: 1px solid #1abc9c;
  width: 90%;
}

.header_section {
  width: 100%;
}

.input_group {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.total_count {
  color: #ecf0f1;
  font-size: 14px;
  text-align: right;
}

.brdTable_title {
  text-align: center;
  color: #1abc9c;
  font-size: 28px;
  margin-bottom: 20px;
  flex-grow: 1;
}

.brdTable_head tr th {
  color: #ecf0f1;
  font-size: 25px;
  font-weight: bold;
  text-align: center;
}

.brdTable_body tr:hover {
  background-color: #4a6a8b;
  transition: 0.3s;
}

.brdTable_body tr:hover td {
  color: #e74c3c;
  transition: 0.3s;
}

.brdTable_body td {
  color: #ecf0f1;
  font-size: 20px;
  text-align: center;
}

.brdTable_cell_idx {
  width: 20%;
}

.brdTable_cell_title {
  width: 30%;
  cursor: pointer;
  text-align: left !important;
}

.brdTable_cell_regdt {
  width: 20%;
}

.brdTable_cell_regid {
  width: 20%;
}

/* 리스트 컨테이너 */
.listWrapper {
  padding: 30px;
  border-radius: 10px;
  background-color: #34495e;
  box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.3);
}

/* 제목 스타일 */
.listTitle {
  text-align: center;
  margin-bottom: 20px;
  font-size: 24px;
  font-weight: bold;
  color: #1abc9c;
}

/* 테이블 스타일 */
.tableRow {
  cursor: pointer;
}

.tableRow:hover {
  background-color: #1abc9c;
  transition: 0.3s;
}

.MuiTableCell-root {
  color: #ecf0f1;
  border-bottom: 1px solid #1abc9c;
}

.MuiTableCell-head {
  color: #bdc3c7;
  font-weight: bold;
}

/* 버튼 스타일 */
.actionButtons {
  margin-top: 20px;
  text-align: right;
}

.createButton {
  background-color: #1abc9c;
  color: #fff;
}

.createButton:hover {
  background-color: #16a085;
}


.buttonStyle {
  margin-top: 20px;
  background-color: #1abc9c !important;
  /* color: #ecf0f1; */
  width: 50%;
  align-self: center;
  display: block;
  margin: 20px auto 0;
}

.buttonStyle:hover {
  background-color: #e74c3c !important;
}

/* 페이징 스타일 */
.pagination_wrap {
  margin: 30px 0;
}

.pagination_wrap :global(.pagination) {
  display: flex;
  justify-content: center;
  margin: 0;
  padding: 0;
}

.pagination_wrap :global(.pagination li) {
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 40px;
  height: 40px;
  background-color: #2c3e50;
  border: 1px solid #1abc9c;
  border-radius: 4px;
  margin: 0 5px;
  transition: all 0.3s ease;
  list-style: none;
}

.pagination_wrap :global(.pagination li a) {
  text-decoration: none;
  color: #ecf0f1;
  font-size: 16px;
  font-weight: 500;
  padding: 8px 12px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.pagination_wrap :global(.pagination li:hover) {
  background-color: #34495e;
  border-color: #e74c3c;
}

.pagination_wrap :global(.pagination li.active) {
  background-color: #1abc9c;
  border-color: #1abc9c;
}

.pagination_wrap :global(.pagination li.active a) {
  color: #2c3e50;
  font-weight: bold;
}

.pagination_wrap :global(.pagination li:first-child),
.pagination_wrap :global(.pagination li:last-child) {
  font-size: 18px;
}

.pagination_wrap :global(.pagination li.disabled) {
  border-color: #465c6f;
  background-color: #2c3e50;
  opacity: 0.5;
  cursor: not-allowed;
}

.pagination_wrap :global(.pagination li.disabled a) {
  color: #465c6f;
}

/* 검색 섹션 전체 스타일 */
.search_section {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  gap: 10px;
  justify-content: center;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
}

/* Select 컴포넌트 스타일 */
.search_select {
  width: 120px;
  height: 40px;
  background-color: #2c3e50 !important;
  color: #fff !important;
  box-sizing: border-box;
}

.search_select :global(.MuiOutlinedInput-notchedOutline) {
  border-color: #1abc9c !important;
}

.search_select:hover :global(.MuiOutlinedInput-notchedOutline) {
  border-color: #e74c3c !important;
}

.search_select :global(.MuiSvgIcon-root) {
  color: #1abc9c !important;
}

.search_select:hover :global(.MuiSvgIcon-root) {
  color: #e74c3c !important;
}

/* 검색 입력창 스타일 */
.search_input {
  padding: 8px 12px;
  height: 40px;
  background-color: #2c3e50;
  border: 1px solid #1abc9c;
  border-radius: 4px;
  color: #fff;
  width: 50%;
  outline: none;
  transition: all 0.3s ease;
  box-sizing: border-box;
}

.search_input::placeholder {
  color: #95a5a6;
}

.search_input:hover {
  border-color: #e74c3c;
  box-shadow: 0 0 5px rgba(26, 188, 156, 0.3);
}

.search_input:focus {
  border-color: #e74c3c;
  box-shadow: 0 0 5px rgba(26, 188, 156, 0.3);
}

/* 검색 버튼 스타일 */
.search_button {
  height: 40px;
  background-color: #1abc9c !important;
  min-width: 80px !important;
  transition: background-color 0.3s ease !important;
  box-sizing: border-box;
}

.search_button:hover {
  background-color: #e74c3c !important;
}

/* Material-UI Select 메뉴 아이템 스타일 */
:global(.MuiMenuItem-root) {
  color: #2c3e50 !important;
}

:global(.MuiMenuItem-root:hover) {
  background-color: rgba(26, 188, 156, 0.1) !important;
}

:global(.MuiMenuItem-root.Mui-selected) {
  background-color: rgba(26, 188, 156, 0.2) !important;
}