.page_wrapper {
    display: flex;
    flex-direction: column;
    gap: 40px;
    /* 섹션 간격 */
    width: 90%;
    margin: 100px auto;
    /* 가운데 정렬 */
}


.skillSection {
    padding: 20px;
    background-color: #34495e;
    border-radius: 8px;
    border: 1px solid #1abc9c;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.2);
}

.skillSection h2 {
    font-size: 24px;
    color: #1abc9c;
    margin-bottom: 15px;
}

.skillSection ul {
    list-style: none;
    padding: 0;
    display: flex;
    flex-wrap: wrap;
    /* 줄바꿈을 허용 */
    gap: 20px;
    /* 각 항목 간 간격 */
}

.skillSection ul li {
    font-size: 18px;
    margin-bottom: 10px;
    color: #ecf0f1;
    position: relative;
    padding-left: 20px;
    width: calc(25% - 20px);
    /* 4개씩 배치되도록 설정 */
    box-sizing: border-box;
    /* 너비 계산에 패딩 포함 */
}

.skillSection ul li:before {
    content: '•';
    position: absolute;
    left: 0;
    color: #1abc9c;
}

.section {
    padding: 20px;
    background-color: #34495e;
    border-radius: 8px;
    border: 1px solid #1abc9c;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.2);
}

.section h2 {
    font-size: 24px;
    color: #1abc9c;
    margin-bottom: 15px;
}

.section ul {
    list-style: none;
    padding: 0;
}

.section ul li {
    font-size: 18px;
    margin-bottom: 10px;
    color: #ecf0f1;
    position: relative;
    padding-left: 20px;
}

.section ul li:before {
    content: '•';
    position: absolute;
    left: 0;
    color: #1abc9c;
}



.career_item h3 {
    font-size: 30px;
    margin-bottom: 10px;
    padding: 20px;
    border-radius: 8px;
    background-color: #2c3e50;
    color: #ecf0f1;
}

.career_item h4 {
    font-size: 20px;
    margin-bottom: 10px;
    padding: 20px;
    border-radius: 8px;
    background-color: #2c3e50;
    color: #ecf0f1;
}

.career_item {
    margin-bottom: 30px;
    padding: 20px;
    border-radius: 8px;
    background-color: #2c3e50;
    border: 1px solid #1abc9c;
    color: #ecf0f1;
}