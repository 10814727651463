/* Footer 전체 스타일 */
.footer {
    width: 100%;
    height: 200px;
    margin: 0;
    padding: 0;
    background-color: #34495e;
    border-top: 1px solid #1abc9c;
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
    margin-top: auto;
}

.footer_content {
    max-width: 1200px;
    width: 100%;
    padding: 20px;
    box-sizing: border-box;
}

/* 아이콘 리스트 */
.icons {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 40px;
    /* 아이콘 간 간격 */
    /* margin: 20px 0; */
    padding: 0;
    list-style: none;
}

/* 개별 아이콘 및 텍스트 스타일 */
.icons_indivisual {

    color: #ecf0f1 !important;
    transition: all 0.3s ease !important;

    &:hover {
        color: #e74c3c !important;
        transform: scale(1.1);
    }
}


/* 팝업 스타일 */
.popup_wrap {
    background-color: #34495e !important;
    color: #ecf0f1 !important; 
    min-width: 300px;
    max-width: 500px;
    width: 90%;
    border: 1px solid #1abc9c;
    border-radius: 8px;
    box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.3);
}

.popup_title {
    color: #1abc9c;
    font-size: 1.5rem;
    font-weight: bold;
    text-align: center;
    border-bottom: 1px solid #1abc9c;
}

.popup_content {
    padding: 20px;
    font-size: 1.1rem;
    line-height: 1.8;
    word-break: break-word;
}

.popup_close {
    background-color: #1abc9c !important;
    color: #ecf0f1 !important;
    padding: 8px 20px;

    &:hover {
        background-color: #e74c3c !important;
    }


}